import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { PublicGuard } from 'database/Guards/AuthGuard';

const Login = Loadable(lazy(() => import('views/auth/Login')));
const ForgetPassword = Loadable(lazy(() => import('views/auth/ForgetPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const publicRoutes = {
  path: '/',
  element: <PublicGuard />,
  children: [
    {
      path: '/',
      element: <MinimalLayout />,
      children: [
        {
          path: 'login',
          element: <Login />
        },
        {
          path: 'forget-password',
          element: <ForgetPassword />
        }
      ]
    }
  ]

};

export default publicRoutes;
