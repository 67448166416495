// assets
import {
  IconReceipt,
  IconReceiptRefund,
} from '@tabler/icons-react';

// constant
const icons = {
  IconReceipt,
  IconReceiptRefund,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const operations = {
  permissions: [
    'Admin',
    'Sales',
    'Accountant',
    'Customer Service',
    'Sales Manager',
    'Field Installation Manager',
    'Warehouse Supervisor',
    'Purchasing Officer',
  ],
  id: 'operations',
  title: 'Operations',
  type: 'group',
  children: [
    {
      permissions: [
        'Admin',
        'Sales',
        'Accountant',
        'Customer Service',
        'Sales Manager',
        'Field Installation Manager',
        'Warehouse Supervisor',
        'Purchasing Officer',
      ],
      id: 'orders',
      title: 'Orders',
      type: 'item',
      breadcrumbs: false,
      icon: icons.IconReceipt,
      url: '/orders',
    }
  ]
};

export default operations;
