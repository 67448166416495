import { format } from 'date-fns';
import { DateTime } from 'luxon';

export const getFirstDateInCurrentMonth = () => {
  var now = getServerDateInEgypt();
  var firstDateInMonth = new Date(now.getFullYear() + "-" + (now.getMonth() + 1) + '-1')
  firstDateInMonth.setHours(0, 0, 0);
  return firstDateInMonth
}

export const getFirstDateInLastMonth = () => {
  const now = getServerDateInEgypt();
  const lastMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1; // Handle December to January transition
  const year = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear(); // Handle year change

  const firstDateInLastMonth = new Date(year, lastMonth, 1);
  firstDateInLastMonth.setHours(0, 0, 0, 0); // Set to start of the day
  return firstDateInLastMonth;
};

export const getLastDateInCurrentMonth = () => {
  var now = getServerDateInEgypt();
  var lastDayDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  var lastDayOfMonth = lastDayDate.getDate();
  var currentYear = now.getFullYear();
  var currentMonth = (now.getMonth() + 1);

  var lastDateInMonth = new Date(currentYear + "-" + currentMonth + '-' + lastDayOfMonth)
  lastDateInMonth.setHours(23, 59, 59)
  return lastDateInMonth
}

export const getLastDateInLastMonth = () => {
  const now = getServerDateInEgypt();
  const lastMonth = now.getMonth(); // Last month is the current month minus one
  const year = lastMonth === 0 ? now.getFullYear() - 1 : now.getFullYear(); // Handle year change when it's January

  // Setting the day to 0 gives the last day of the previous month
  const lastDayDate = new Date(year, lastMonth, 0);
  lastDayDate.setHours(23, 59, 59); // Set to end of the day

  return lastDayDate;
};

export const timestampToDateString = (timestamp, dateFormat) => {
  var date = timestamp.toDate();
  return format(date, dateFormat);
};

export const getLastHoursInDate = (date) => {
  const modifiedDate = new Date(date)
  modifiedDate.setHours(23, 59, 59)
  return modifiedDate
}

export const getFirstHoursInDate = (date) => {
  const modifiedDate = new Date(date)
  modifiedDate.setHours(0, 0, 0)
  return modifiedDate
}

export const getCurrentDateAndTimeAsString = () => {
  const date = getServerDateInEgypt();
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}

export const getCurrentDateAsString = () => {
  const date = getServerDateInEgypt();
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${day}_${month}_${year}`;
}

export const splitDate = (dateObj) => {
  // Format 1: EEE (Day Name)
  const dayName = dateObj.toLocaleString('en-US', { weekday: 'short' });

  // Format 2: d MMM yyyy (Date)
  const formattedDate = dateObj.toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  // Format 3: h:mm a (Time)
  const formattedTime = dateObj.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return { dayName, formattedDate, formattedTime };
}

export const getExcelDateFormat = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const getCurrentMonthName = () => {
  const monthNames =
    ["January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

  const d = getServerDateInEgypt();
  return monthNames[d.getMonth()]
}

export function getHumanReadableFormat(timestamp) {
  const now = getServerDateInEgypt();
  const activityDate = timestamp.toDate();
  const timeDifference = now.getTime() - activityDate.getTime(); // Ensure accurate difference in milliseconds
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    const remainingMinutes = minutes % 60;
    return `${hours} hours ${remainingMinutes} minutes ago`;
  } else {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const yesterdayString = activityDate.toLocaleTimeString(undefined, options);

    if (days === 1) {
      return `Yesterday at ${yesterdayString}`;
    } else {
      const fullDateOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
      return activityDate.toLocaleDateString(undefined, fullDateOptions);
    }
  }
}

export function getFormattedDate(timestamp) {
  const activityDate = timestamp.toDate();

  const day = activityDate.getDate();
  const month = activityDate.toLocaleString('default', { month: 'long' });
  const year = activityDate.getFullYear();

  return `${day} ${month} ${year}`;
}

// Function to get the current date and time adjusted for Egypt timezone
export function getServerDateInEgypt() {
  // Assuming the server time is in UTC
  const serverTimeUTC = DateTime.now().setZone('UTC');

  // Convert server time to Egypt timezone
  const egyptTime = serverTimeUTC.setZone('Africa/Cairo');

  // Return as a JavaScript Date object
  return egyptTime.toJSDate();
}