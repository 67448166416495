import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../Providers/AuthProvider";
import { isUserAuthorized } from "model/permissions";

export function PermissionsGuard({ children, props })
{
  const { globalProfile } = useContext(AuthContext);
  return isUserAuthorized(globalProfile, props) ? children : <Navigate to="/unauthorized" />;
}
