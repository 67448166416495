import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { SuspendedGuard } from 'database/Guards/AuthGuard';
const Deactivated = Loadable(lazy(() => import('views/Deactivated')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const suspendedRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'deactivated',
      element: <SuspendedGuard />,
      children: [
        {
          path: '',
          element: <Deactivated />
        }
      ]
    }
  ]
};

export default suspendedRoutes;
