import {
    IconGitBranch,
} from '@tabler/icons-react';

const technical = {
    permissions: ['Technical'],
    id: 'technical',
    title: 'Technical',
    type: 'group',
    children: [
        {
            id: 'technical',
            title: 'Technical',
            permissions: ['Technical'],
            breadcrumbs: false,
            type: 'item',
            icon: IconGitBranch,
            url: '/technical',
        }
    ]
};

export default technical;
