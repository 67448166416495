import { useRoutes } from 'react-router-dom';

// routes
import authenticatedRoutes from './authenticatedRoutes';
import publicRoutes from './publicRoutes';
import unauthorizedRoutes from './unauthorizedRoutes';
import notFoundRoutes from './notFoundRoutes';
import maintaianceRoutes from './maintaianceRoutes';
import suspendedRoutes from './suspendedRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const routes = useRoutes([authenticatedRoutes, publicRoutes, unauthorizedRoutes, suspendedRoutes, maintaianceRoutes, notFoundRoutes]);

  return routes
}
