// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Tooltip, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Brightness2, Brightness3 } from '@mui/icons-material';

// assets
import { SET_THEME_MODE } from 'store/actions';

const ThemeModeSection = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const mode = localStorage.getItem('colorMode') || (prefersDarkMode ? 'dark' : 'light') || 'light';

  const tooltipTitle = theme.palette.mode === 'dark' ? 'Switch to Light Mode' : 'Switch to Dark Mode';

  const handleToggle = () => {
    dispatch({ type: SET_THEME_MODE, mode: mode === 'dark' ? 'light' : 'dark' });
  }

  return (
    <Tooltip sx={{ ml: 2 }} title={tooltipTitle} arrow>
      <Button
        onClick={handleToggle}
        variant='contained'>
        {theme.palette.mode === 'dark' ? <Brightness2 /> : <Brightness3 sx={{ color: '#ffffff' }} />}
      </Button>
    </Tooltip>
  );
};

export default ThemeModeSection;
