// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import PermissionView from 'database/Guards/PermissionView';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () =>
{

  const navItems = menuItem.items.map((item) =>
  {
    switch (item.type)
    {
      case 'group':
        return (
          <PermissionView key={item.id} requiredPermissions={item.permissions} >
            <NavGroup item={item} />
          </PermissionView>
        )

      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
