import {
  dateToFirebaseTimestamp,
  firebaseTimestampToDateObject,
  firebaseTimestampToDateString,
  firestoreGetObjectByID,
  firestoreSetObject,
  incrementFirestoreField,
  registerDocumentListener
} from 'database/API/database'

export const dateToServerTimeStamp = (date) => {
  return dateToFirebaseTimestamp(date)
}

export const serverTimestampToDateString = (timestamp) => {
  return firebaseTimestampToDateString(timestamp)
}

export const attachSystemListener = async (callback) => {
  const path = 'info/data';
  return await registerDocumentListener(path, callback);
};


export const serverTimestampToDateObject = (timestamp) => {
  return firebaseTimestampToDateObject(timestamp);
};

export const getSystemInfo = () => {
  return new Promise((resolve, reject) => {
    firestoreGetObjectByID('info/data')
      .then((info) => {
        console.log('getSystemInfo success')
        resolve(info);
      })
      .catch((error) => {
        console.log('getSystemInfo Fail')
        reject(error);
      });
  });
}

export const updatesystemInfo = (object) => {
  return new Promise(async (resolve, reject) => {
    await firestoreSetObject('info/data', object)
      .then((user) => {
        console.log('updatesystemInfo success')
        resolve(user);
      })
      .catch((error) => {
        console.log('UpdateupdatesystemInfoUser Fail')
        reject(error);
      });
  });
}

export const getAndIncrementLastCustomerRef = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const systemInfo = await firestoreGetObjectByID('info/data');
      const lastCustomerNo = systemInfo.lastCustomerNo;

      await incrementFirestoreField('info/data', 'lastCustomerNo', 1);

      console.log('incrementLastCustomerRef success');
      resolve(lastCustomerNo);
    } catch (error) {
      console.log('incrementLastCustomerRef fail');
      reject(error);
    }
  });
};

export const getAndIncrementLastInvoiceRef = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const systemInfo = await firestoreGetObjectByID('info/data');
      const lastInvoiceNo = systemInfo.lastInvoiceNo;

      await incrementFirestoreField('info/data', 'lastInvoiceNo', 1);

      console.log('getAndIncrementLastInvoiceRef success');
      resolve(lastInvoiceNo);
    } catch (error) {
      console.log('getAndIncrementLastInvoiceRef fail');
      reject(error);
    }
  });
};