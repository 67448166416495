import { Chip, Stack, Typography, useTheme } from '@mui/material'
import { IconShieldCheck } from '@tabler/icons-react';
import { AuthContext } from 'database/Providers/AuthProvider'
import React, { useContext } from 'react'

export default function PermissionsViewSection() {

    const theme = useTheme();
    const { globalProfile } = useContext(AuthContext);
    return (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
            {globalProfile.permissions.map((item) => (
                <Chip
                    sx={{ py: 2.2, px: 1, borderRadius: 1 }}
                    key={item}
                    icon={<IconShieldCheck color={theme.palette.success.main} />}
                    label={<Typography>{item}</Typography>}
                />
            ))}
        </Stack>
    )
}
