
export const styles = {
  popper: {
    zIndex: 9999
  },
  logo: {
    maxWidth: '150px',
    marginBottom: 5,
  },
  container: {
    margin: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
  },
  termsAndConditions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  logout: {
    m: 2
  }
};
