
export const delay = ms => new Promise(res => setTimeout(res, ms));

export const DefaultLatLng = {
  lat: 30.033333,
  lng: 31.233334
}

export const activationOptions = [
  {
    label: "Activated",
    value: ("Activated")
  },
  {
    label: "Deactivated",
    value: ("Deactivated")
  }
]



export const userPermissions = [
  {
    label: "Admin",
    value: ("Admin")
  },
  {
    label: "Sales",
    value: ("Sales")
  },
  {
    label: "Accountant",
    value: ("Accountant")
  },
  {
    label: "Customer Service",
    value: ("Customer Service")
  },
  {
    label: "Sales Manager",
    value: ("Sales Manager")
  },
  {
    label: "Warehouse Supervisor",
    value: ("Warehouse Supervisor")
  },
  {
    label: "Purchasing Officer",
    value: ("Purchasing Officer")
  },
  {
    label: "Field Installation Manager",
    value: ("Field Installation Manager")
  }
]

export const productsType = [
  {
    label: "Sector",
    value: ("Sector")
  },
  {
    label: "Box",
    value: ("Box")
  },
  {
    label: "Box Side",
    value: ("Box Side")
  },
  {
    label: "Motor",
    value: ("Motor")
  },
  {
    label: "Track",
    value: ("Track")
  },
  {
    label: "Accessory",
    value: ("Accessory")
  }
]

export const QuotationCategoryList = [
  {
    label: "Shutter",
    value: ("shutter")
  },
  {
    label: "Sectors",
    value: ("sectors")
  },
  {
    label: "Motors",
    value: ("motors")
  },
  {
    label: "Tracks",
    value: ("tracks")
  },
  {
    label: "Boxes",
    value: ("boxes")
  },
  {
    label: "Box Sides",
    value: ("boxSides")
  },
  {
    label: "Accessories",
    value: ("accessories")
  }
]

export const materialType = [
  {
    label: "Steel",
    value: ("Steel")
  },
  {
    label: "Antimonium",
    value: ("Antimonium")
  },
]

export const installationMethod = [
  {
    label: "Normal",
    value: ("Normal")
  },
  {
    label: "Latch",
    value: ("Latch")
  },
]

export const ElectricityDirection = [
  {
    label: "يمين",
    value: ("يمين")
  },
  {
    label: "شمال",
    value: ("شمال")
  },
  {
    label: "بدون كهرباء",
    value: ("بدون كهرباء")
  },
]

export const JobOrderInstallationMethod = [
  {
    label: "تحت الحلق",
    value: ("تحت الحلق")
  },
  {
    label: "لطش",
    value: ("لطش")
  },
  {
    label: "لطش ارتفاع",
    value: ("لطش ارتفاع")
  },
  {
    label: "زيادة فى العرض فقط",
    value: ("زيادة فى العرض فقط")
  },
  {
    label: "زيادة من جمب واحد",
    value: ("زيادة من جمب واحد")
  },
]

export const JobOrderTracksType = [
  {
    label: "مسلخة",
    value: ("مسلخة")
  },
  {
    label: "غير مسلخة",
    value: ("غير مسلخة")
  },
  {
    label: "مجرى مسلخة ومجرى غير مسلخة",
    value: ("مجرى مسلخة ومجرى غير مسلخة")
  },
]

export const PaymentMethods = [
  "Cash",
  "Visa",
  "Bank Transfer",
  "Valu",
  "Outside Transfer",
  "Instapay",
  "Cheque",
  "Bank Deposit",
]

export const getLabel = (list, value) => {
  for (var i = 0; i < list.length; i++) {
    if (list[i].value === value) {
      return list[i].label;
    }
  }
}

export const getItemByValue = (list, value) => {
  for (var i = 0; i < list.length; i++) {
    if (list[i].value === value) {
      return list[i];
    }
  }
}