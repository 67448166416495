import Loading from 'ui-component/layout/Loading';

import React, {
  useContext,
  useState,
  useEffect
} from 'react';

import {
  Navigate, Outlet
} from 'react-router-dom';

import {
  AuthContext
} from 'database/Providers/AuthProvider';

export function AuthGuard() {
  const { authenticatedUser, systemInfo, globalProfile, loading } = useContext(AuthContext);

  if (loading) {
    return <Loading />
  }

  if (!authenticatedUser) {
    return <Navigate to='/login' />;
  }

  if (systemInfo && globalProfile) {
    if (systemInfo.operation.admin_panel === false && !globalProfile?.permissions?.includes('Technical')) {
      return <Navigate to='/maintenance' />;
    }

    if (globalProfile.status === 'Deactivated') {
      return <Navigate to='/deactivated' />;
    }
  }

  if (!systemInfo || !globalProfile) {
    return null
  }
  return <Outlet />;
}

export function UnauthorizedGuard() {
  const { authenticatedUser, globalProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authenticatedUser !== null && globalProfile) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [authenticatedUser, globalProfile]);

  if (loading) {
    return <Loading />
  }
  if (!authenticatedUser) {
    return <Navigate to='/login' />;
  }
  return <Outlet />;
}

export function SuspendedGuard() {
  const { authenticatedUser, globalProfile, loading } = useContext(AuthContext);

  if (loading) {
    return <Loading />
  }
  if (!authenticatedUser) {
    return <Navigate to='/login' />;
  }
  if (globalProfile?.status === 'Activated') {
    return <Navigate to='/' />;
  }

  return <Outlet />;
}

export function PublicGuard() {
  const { authenticatedUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [authenticatedUser]);

  if (loading) {
    return <Loading />
  }

  if (authenticatedUser) {
    return <Navigate to='/' />;
  }
  return <Outlet />;
}

export function SystemMaintaianceGuard() {
  const { authenticatedUser, systemInfo, loading } = useContext(AuthContext);

  if (loading) {
    return <Loading />;
  }
  if (!authenticatedUser) {
    return <Navigate to='/login' />;
  }
  if (systemInfo?.operation.admin_panel) {
    return <Navigate to='/' />;
  }
  return <Outlet />;
}