import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
const NotFound = Loadable(lazy(() => import('views/auth/NotFound')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const notFoundRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '*',
      element: <NotFound />
    }
  ]
};

export default notFoundRoutes;
