import logo from 'assets/images/logo-min-no-slogan.png';
import { Box } from '@mui/material'

// ==============================|| LOGO SVG ||============================== //

const Logo = () =>
{
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={2}>
      <img src={logo} alt="Evo Shutter" width="80" />
    </Box>
  );
};

export default Logo;
