import logo from 'assets/images/logo-min-no-slogan.png';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'database/Providers/AuthProvider';
import { Popover, Paper, Typography, Link, Divider, Box, Button } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { styles } from './styles'

const ProfileSection = () =>
{
  const { globalProfile, logout } = useContext(AuthContext);
  const [shortName, setShortName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() =>
  {
    if (globalProfile && globalProfile.name)
    {
      setShortName(globalProfile.name);
      setLoading(false);
    }
  }, [globalProfile]);

  const handleClick = (event) =>
  {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  if (loading)
  {
    return null;
  }

  return (
    <React.Fragment>

      <Button
        variant={anchorEl ? 'contained' : 'outlined'}
        sx={{
          marginLeft: '1rem',
          '&:hover': {
            backgroundColor: anchorEl ? 'inherit' : 'primary.main',
            color: anchorEl ? 'primary.main' : 'white',
          },
        }}
        onClick={handleClick} >
        {shortName}
        {anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>

      <Popover
        style={styles.popper}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}>
        <Paper sx={{ background: 'transparent', boxShadow: 'none' }}>
          <Box sx={styles.container}>
            <img alt="logo" src={logo} style={styles.logo} />

            <Typography sx={{ mt: 2 }} variant="caption">{globalProfile.email}</Typography>
            <Typography variant="caption">{globalProfile?.phoneNumber}</Typography>

            <Button variant='outlined' sx={styles.logout} onClick={() => { logout() }}>Sign Out</Button>

            <Divider sx={{ my: 2 }} />

            <Box sx={styles.termsAndConditions}>
              <Link
                variant='caption'
                href="https://evo-shutter.app/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </Link>

              <Link
                variant='caption'
                href="https://evo-shutter.app/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </React.Fragment >
  );
};

export default ProfileSection;
