import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization, prefersDarkMode) => {
  const color = colors;
  const mode = localStorage.getItem('colorMode') || (prefersDarkMode ? 'dark' : 'light')

  const themeOption = {
    mode,
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    customization
  };

  const darkThemeOption = {
    mode,
    colors: color,
    heading: color.color,
    paper: color.darkPaper,
    backgroundDefault: color.darkPaper,
    background: color.darkBackground,
    darkTextPrimary: color.paper,
    darkTextSecondary: color.paper,
    textDark: color.paper,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.primaryMain,
    divider: color.grey700,
    customization
  };

  const selectedThemeOptions = mode === 'dark' ? darkThemeOption : themeOption;

  const themeOptions = {
    direction: 'ltr',
    palette: themePalette(selectedThemeOptions),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px'
        }
      }
    },
    typography: themeTypography(selectedThemeOptions)
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(selectedThemeOptions);

  return themes;
};

export default theme;
