import { nanoid } from 'nanoid';

export const arrayToObject = (array, key) => {
  return array.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
}

export const objectToArray = (obj) => {
  return Object.values(obj);
};



// Function to generate a unique ID with specified characters
export const generateUniqueId = (length = 21) => {
  // Custom alphabet including uppercase and lowercase letters, and numbers from 1 to 9
  const customAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789';

  return nanoid(length, customAlphabet);
};