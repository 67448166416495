import {
  IconArchive,
  IconTopologyStar,
  IconUsersPlus
} from '@tabler/icons-react';

const customers = {
  permissions: [
    'Admin',
    'Sales',
    'Accountant',
    'Sales Manager',
    'Field Installation Manager',
    'Warehouse Supervisor',
    'Purchasing Officer',
  ],
  id: 'customers',
  title: 'customers',
  type: 'group',
  children: [
    {
      permissions: [
        'Admin',
        'Sales',
        'Accountant',
        'Sales Manager',
        'Field Installation Manager',
        'Warehouse Supervisor',
        'Purchasing Officer',
      ],
      id: 'customers',
      title: 'Customers',
      type: 'item',
      url: '/customers',
      icon: IconUsersPlus,
      breadcrumbs: false
    },
    {
      permissions: [
        'Admin',
        'Sales',
        'Sales Manager',
        'Field Installation Manager',
      ],
      id: 'areas',
      title: 'Areas',
      type: 'item',
      url: '/areas',
      icon: IconArchive,
      breadcrumbs: false
    },
    {
      permissions: [
        'Admin',
        'Sales',
        'Sales Manager',
        'Field Installation Manager',
      ],
      id: 'referrals',
      title: 'Referrals',
      type: 'item',
      url: '/referrals',
      icon: IconTopologyStar,
      breadcrumbs: false
    }
  ]
};

export default customers;
