import
  {
    firestoreDatabase,
    collection,
    query,
    where,
    orderBy,
    limit,
    startAfter,
    getDocs
  } from 'database/Config/firebaseInit';

import { useCallback, useState, useRef } from 'react';

export const usePagination = (path, filters, orders, limitValue) =>
{
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [baseQuery, setBaseQuery] = useState(null);

  const buildQuery = useCallback(() =>
  {
    let prepareQuery = query(collection(firestoreDatabase, path));

    filters.forEach((filter) =>
    {
      prepareQuery = query(prepareQuery, where(filter.key, filter.op, filter.value));
    });

    orders.forEach((order) =>
    {
      prepareQuery = query(prepareQuery, orderBy(order.key, order.value));
    });

    if (limitValue !== 0)
    {
      prepareQuery = query(prepareQuery, limit(limitValue));
    }

    return prepareQuery;
  }, [path, filters, orders, limitValue]);

  const getFirstPageRef = useRef();

  const getFirstPage = useCallback(() =>
  {
    setLoading(true);
    setDocuments([]);

    const prepareQuery = buildQuery();

    getDocs(prepareQuery).then((querySnapshot) =>
    {
      if (!querySnapshot.empty)
      {
        const result = querySnapshot.docs.map((doc) => doc.data());
        setDocuments(result);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(true);
      } else
      {
        setHasMore(false);
      }
      setBaseQuery(prepareQuery);
      setLoading(false);
    });
  }, [buildQuery]);

  getFirstPageRef.current = getFirstPage;

  const loadMore = useCallback(() =>
  {
    if (hasMore && lastDoc)
    {
      setLoading(true);
      const prepareQuery = query(baseQuery, startAfter(lastDoc));
      getDocs(prepareQuery).then((querySnapshot) =>
      {
        if (!querySnapshot.empty)
        {
          const result = querySnapshot.docs.map((doc) => doc.data());
          setDocuments((prevDocuments) => [...prevDocuments, ...result]);
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setHasMore(true);
        } else
        {
          setHasMore(false);
        }
        setLoading(false);
      });
    }
  }, [hasMore, lastDoc, baseQuery]);

  return { getFirstPage: getFirstPageRef, loadMore, hasMore, documents, loading };
};
