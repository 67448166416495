import
{
  firebaseStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from '../Config/firebaseInit';

import { v4 as uuid } from 'uuid';

export const firebaseStorageUploadRandomNameFile = async (path, file) =>
{
  return new Promise((resolve, reject) =>
  {
    const randomFileName = uuid() + file.name;
    const storageRef = ref(firebaseStorage, path + randomFileName);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) =>
      {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) =>
      {
        reject(error);
      },
      () =>
      {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
        {
          console.log('File available at', downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};

export const firebaseStorageUploadFile = async (path, fileName, file) =>
{
  return new Promise(async (resolve, reject) =>
  {
    try
    {
      const storageRef = ref(firebaseStorage, path + fileName);

      const uploadTask = uploadBytesResumable(storageRef, file);
      console.log(uploadTask)
      uploadTask.on(
        'state_changed',
        (snapshot) =>
        {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) =>
        {
          reject(error);
        },
        () =>
        {
          try
          {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
            {
              console.log('File available at', downloadURL);
              resolve(downloadURL);
            });
          } catch (error)
          {
            reject(error);
          }
        }
      );
    } catch (error)
    {
      reject(error);
    }
  });
};


export const firebaseStorageDeleteFile = async (imageUrl) =>
{
  return new Promise((resolve, reject) =>
  {
    const imageRef = ref(firebaseStorage, imageUrl);

    deleteObject(imageRef)
      .then(() =>
      {
        console.log('firebaseStorageDeleteFile Success!');
        resolve(imageUrl);
      })
      .catch((error) =>
      {
        console.error('firebaseStorageDeleteFile Fail: ', error);
        reject(error);
      });
  });
};