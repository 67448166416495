import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { UnauthorizedGuard } from 'database/Guards/AuthGuard';

const Unauthorized = Loadable(lazy(() => import('views/auth/Unauthorized')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const unauthorizedRoutes = {
  path: '/',
  element: <UnauthorizedGuard />,
  children: [{
    path: '/',
    element: <MinimalLayout />,
    children: [
      {
        path: '/unauthorized',
        element: <Unauthorized />
      }
    ]
  }]

};

export default unauthorizedRoutes;
