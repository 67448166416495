import { AuthContext } from "database/Providers/AuthProvider";
import { useContext } from "react";
import { isUserAuthorized } from "model/permissions";

const PermissionView = ({ requiredPermissions, children }) =>
{
  const { globalProfile } = useContext(AuthContext);

  if (!requiredPermissions)
  {
    return children;
  }

  const hasPermission = isUserAuthorized(globalProfile, requiredPermissions);

  return hasPermission ? children : null;
};

export default PermissionView;
