import {
  firestoreGetObjectByID,
  firestoreSetObject,
  firestorePushObject,
  firestoreGetCollection,
  registerCollectionListener,
} from 'database/API/database'

import {
  usePagination
} from 'database/Hooks/usePagination';

export const attachAreasListener = async (callback) => {
  return await registerCollectionListener(`areas`, callback)
}

export const getAreaByID = (areaID) => {
  return new Promise((resolve, reject) => {
    firestoreGetObjectByID('areas/' + areaID)
      .then((data) => {
        console.log('getAreaByID success')
        resolve(data);
      })
      .catch((error) => {
        console.log('getAreaByID Fail')
        reject(error);
      });
  });
}

export const getAllAreas = () => {
  return new Promise((resolve, reject) => {
    const path = 'areas'
    const filters = []
    const orders = [
      {
        'key': 'created',
        'value': 'asc'
      }
    ]
    const limit = 0;

    firestoreGetCollection(path, filters, orders, limit)
      .then((areas) => {
        console.log('getAllAreas success')
        resolve(areas);
      })
      .catch((error) => {
        console.log('getAllAreas Fail')
        reject(error);
      });
  });
}

export const updateArea = (area) => {
  return new Promise(async (resolve, reject) => {
    await firestoreSetObject(`areas/${area.id}`, area)
      .then((area) => {
        console.log('updateArea success')
        resolve(area);
      })
      .catch((error) => {
        console.log('updateArea Fail')
        reject(error);
      });
  });
}

export const addNewArea = (area) => {
  return new Promise(async (resolve, reject) => {
    await firestorePushObject('areas', area)
      .then((data) => {
        console.log('addNewArea success')
        resolve(data);
      })
      .catch((error) => {
        console.log('addNewArea Fail')
        reject(error);
      });
  });
}

export const usePaginateAreas = (mode, filter) => {
  const path = 'areas';
  let filters = [];
  if (mode === 'All') {
    filters = [];
  }
  else if (mode === 'search') {
    filters = [
      {
        'key': 'searchKeys',
        'op': 'array-contains-any',
        'value': [filter]
      }
    ];
  }
  const orders = [
    {
      key: 'created',
      value: 'desc',
    },
  ];
  const limit = 10;

  const { getFirstPage, loadMore, hasMore, documents, loading } = usePagination(
    path,
    filters,
    orders,
    limit,
  );

  return {
    getFirstPage,
    loadMore,
    hasMore,
    areas: documents,
    loading,
  };
};
