import {
  adminChangeUserPassword,
  adminCreateUser
} from 'database/API/auth';

import {
  firestoreGetObjectByID,
  firestoreSetObject,
  firestoreGetCollection,
  registerDocumentListener,
  getMultipleDocumentsByID
} from 'database/API/database'

import {
  usePagination
} from 'database/Hooks/usePagination';
import { arrayToObject } from 'utility/javaScriptHelper';

export const attachProfileListener = async (userID, callback) => {
  const path = 'users/' + userID
  return await registerDocumentListener(path, callback)
}

export const getOrderHistoryUsersMap = (order) => {
  return new Promise((resolve, reject) => {
    if (!order.history || !Array.isArray(order.history)) {
      return resolve({}); // Return an empty map if order.history is not available or not an array
    }

    // Extract user IDs from order.history
    const ids = order.history.map(item => item.reference).filter(id => id); // Filter out any undefined or null IDs

    if (ids.length === 0) {
      return resolve({}); // Return an empty map if there are no valid IDs
    }

    getMultipleDocumentsByID('users', ids)
      .then((data) => {
        console.log('getOrderHistoryUsersMap success');

        // Convert the array to a map using arrayToObject function
        const usersMap = arrayToObject(data, 'id');
        resolve(usersMap);
      })
      .catch((error) => {
        console.log('getOrderHistoryUsersMap Fail');
        reject(error);
      });
  });
}


export const usePaginateUsers = (mode, filter) => {
  const path = 'users';
  let filters = [];
  if (mode === 'all') {
    filters = [];
  }
  else if (mode === 'status') {
    filters = [
      {
        key: 'status',
        op: '==',
        value: filter,
      },
    ];
  }
  else if (mode === 'search') {
    filters = [
      {
        'key': 'searchKeys',
        'op': 'array-contains-any',
        'value': [filter]
      }
    ];
  }
  const orders = [
    {
      key: 'created',
      value: 'desc',
    },
  ];
  const limit = 10;

  const { getFirstPage, loadMore, hasMore, documents, loading } = usePagination(
    path,
    filters,
    orders,
    limit,
  );

  return {
    getFirstPage,
    loadMore,
    hasMore,
    users: documents,
    loading,
  };
};


export const addNewUser = (user) => {
  return new Promise(async (resolve, reject) => {
    await firestoreSetObject('users/' + user.id, user)
      .then((data) => {
        console.log('addNewUser success')
        resolve(data);
      })
      .catch((error) => {
        console.log('addNewUser Fail')
        reject(error);
      });
  });
}

export const getUserbyID = (userID) => {
  return new Promise((resolve, reject) => {
    firestoreGetObjectByID('users/' + userID)
      .then((data) => {
        console.log('getUserbyID success')
        resolve(data);
      })
      .catch((error) => {
        console.log('getUserbyID Fail')
        reject(error);
      });
  });
}

export const updateUser = (user) => {
  return new Promise(async (resolve, reject) => {
    await firestoreSetObject(`users/${user.id}`, user)
      .then((user) => {
        console.log('updateUser success')
        resolve(user);
      })
      .catch((error) => {
        console.log('updateUser Fail')
        reject(error);
      });
  });
}

export const createSystemUser = (email, password) => {
  return new Promise(async (resolve, reject) => {
    await adminCreateUser(email, password)
      .then((admin) => {
        console.log('createSystemUser success')
        resolve(admin);
      })
      .catch((error) => {
        console.log('createSystemUser Fail')
        reject(error);
      });
  });
}

export const updateUserPassword = (userID, newPassword) => {
  return new Promise(async (resolve, reject) => {
    await adminChangeUserPassword(userID, newPassword)
      .then((admin) => {
        console.log('updateUserPassword success')
        resolve(admin);
      })
      .catch((error) => {
        console.log('updateUserPassword Fail')
        reject(error);
      });
  });
}

export const getAllUsersByPermissions = (permissions) => {
  return new Promise((resolve, reject) => {
    const path = 'users'
    const filters = [
      {
        'key': 'permissions',
        'op': 'array-contains-any',
        'value': permissions
      }
    ]
    const orders = []
    const limit = 0;

    firestoreGetCollection(path, filters, orders, limit)
      .then((sources) => {
        console.log('getAllUsersByPermissions success')
        resolve(sources);
      })
      .catch((error) => {
        console.log('getAllUsersByPermissions Fail')
        reject(error);
      });
  });
}