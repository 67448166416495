export const isUserAuthorized = (user, list) =>
{
  if (!list)
  {
    return false;
  }

  return list.some(permission => user.permissions.includes(permission));
};

export const isUserType = (user, type) =>
{
  return user.permissions.includes(type) && user.permissions.length === 1;
};
