import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useMediaQuery } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import AuthProvider from 'database/Providers/AuthProvider';

// ==============================|| APP ||============================== //

const App = () =>
{
  const customization = useSelector((state) => state.customization);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization, prefersDarkMode)}>
        <CssBaseline />
        <NavigationScroll>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
